<template>
  <div
    class="ui-select"
    :class="[denseClass, sizeClass, errorClass]"
    :style="{ width }"
  >
    <VueMultiselect
      v-model="value"
      :options="options"
      :disabled="disabled"
      :searchable="false"
      :show-labels="false"
      :placeholder="placeholder"
      :track-by="options.some(option => !!option.id) ? 'id' : undefined"
      :label="options.some(option => !!option.label) ? 'label' : undefined"
      :allow-empty="allowEmpty"
      @open="onOpen"
      @close="onClose"
      @select="onSelect"
    >
      <template v-slot:singleLabel="{ option }">
        <Icon
          v-if="hasIcon && option.icon"
          :name="option.icon"
          class="mr-2"
          :sides="iconSize"
        />
        <TypographyEllipsis :size="size">
          <template v-if="option.label">
            {{ option.label }}
          </template>
          <template v-if="!option.label">
            {{ value }}
          </template>
        </TypographyEllipsis>
      </template>

      <template v-if="hasIcon" v-slot:option="{ option }">
        <div v-if="option.isEnabled === false">
          <Flex flex-direction="row" align-center>
            <Icon
              v-if="option.icon"
              :name="option.icon"
              color="error"
              sides="16"
            />
            <Typography color="error">
              {{ '【要修正】' }}
            </Typography>
            <TypographyEllipsis color="disabled">
              {{ option.label }}
            </TypographyEllipsis>
          </Flex>
        </div>
        <div v-else>
          <Icon
            v-if="option.icon"
            :name="option.icon"
            :class="iconMargin"
            :sides="iconSize"
            :color="iconColor"
          />
          {{ option.label }}
        </div>
      </template>

      <template #caret>
        <Icon
          name="caret-down"
          :color="disabled ? 'disabled' : 'primary'"
          sides="12"
          class="multiselect-icon"
        />
      </template>
      <template #noOptions>
        <slot name="noOptions" />
      </template>
    </VueMultiselect>
    <ErrorMessage v-if="!!errorMessage" class="mt-1">
      {{ errorMessage }}
    </ErrorMessage>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import VueMultiselect from 'vue-multiselect';
import ErrorMessage from '@/components/ui/ErrorMessage.vue';
import Icon from '@/components/ui/Icon.vue';
import Flex from '@/components/layout/Flex.vue';
import Typography from '@/components/ui/Typography.vue';
import TypographyEllipsis from '@/components/ui/TypographyEllipsis.vue';

export default defineComponent({
  name: 'Select',
  components: {
    ErrorMessage,
    Icon,
    Typography,
    TypographyEllipsis,
    Flex,
    VueMultiselect
  },
  props: {
    modelValue: [String, Object, Number],
    options: Array, // string[] | { icon: string(icon name); label: string; value: string }[]
    disabled: Boolean,
    size: {
      type: String,
      default: 's'
    },
    iconSize: {
      type: String,
      default: '16'
    },
    iconMargin: {
      type: String,
      default: 'mr-2'
    },
    iconColor: String,
    placeholder: {
      type: String,
      default: '選択してください'
    },
    errorMessage: String,
    isError: Boolean,
    dense: Boolean,
    allowEmpty: {
      type: Boolean,
      default: false
    },
    width: String
  },
  emits: ['update:modelValue', 'open', 'close', 'change'],
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });

    const hasIcon = computed(() => props.options.some(some => !!some.icon));

    return {
      value,
      hasIcon,
      denseClass: computed(() => (props.dense ? 'dense' : undefined)),
      errorClass: computed(() =>
        props.errorMessage || props.isError ? 'error' : undefined
      ),
      sizeClass: computed(() => props.size || 's'),
      onOpen: () => emit('open'),
      onClose: val => emit('close', val),
      onSelect: selectedValue => emit('change', selectedValue)
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/effect';
@import '@/styles/multi-select';
@import '@/styles/spacing';
@import '@/styles/layout';

.select-error {
  border-color: var(--border-error-color);
  box-shadow: 0px 0px 4px var(--shadow-error-color);
  &:-webkit-autofill {
    box-shadow: 0px 0px 4px var(--shadow-error-color),
      0 0 0px 1000px var(--form-background-color) inset;
  }
}

.multiselect__select {
  &::before {
    color: var(--primary-color);
    border-color: var(--primary-color) transparent transparent transparent;
  }
}

.multiselect__single {
  display: flex;
  align-items: center;
  background: transparent;
  margin-bottom: 0;
  padding-left: 0;
  height: 100%;
  max-width: calc(100% - 12px);

  .ui-typography.ellipsis {
    line-height: 1.5 !important;
  }
}

.ui-select {
  .invalid .multiselect__tags {
    @extend .select-error;
  }

  .multiselect__tags {
    height: 100%;
    min-height: 100%;
    &:hover {
      cursor: pointer;
    }
  }

  &.s {
    &:not(.dense) {
      height: $form-s-height;
    }

    .multiselect {
      height: $input-height-s;
      min-height: $input-height-s;
    }

    .multiselect__select {
      width: 38px;
      height: 38px;
    }

    &.dense .multiselect__content-wrapper {
      margin-top: $spacing-base * 2;
    }

    .multiselect__placeholder {
      line-height: $input-height-s - 2;
    }
  }

  &.m {
    &:not(.dense) {
      height: $form-m-height;
    }

    .multiselect {
      height: $input-height-m;
    }

    &.dense .multiselect__content-wrapper {
      margin-top: $spacing-base * 3;
    }

    .multiselect__placeholder {
      line-height: $input-height-m - 2;
    }
  }

  &.error {
    .multiselect__tags {
      @extend .select-error;
    }
  }
}
</style>
